<template>
  <div id="internationalSwift">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }}</div>
        <div class="form_deposit" v-if="formFlag">
          <component :is="HeaderComponent"></component>
          <el-form
            label-position="top"
            :model="internationalForm"
            ref="internationalForm"
            status-icon
            :rules="internationalRules"
          >
            <div class="box_left box">
              <strong class="title"><span>1</span>{{ $t('deposit.default.deposit') }} </strong>
              <div class="box_left_content form_content_box">
                <div class="info mb-5">
                  <p>
                    {{ $t('deposit.intSwift.desc', { platform: $platform.info.fullName }) }}
                  </p>
                  <p>{{ $t('deposit.intSwift.options') }}</p>
                </div>
                <el-row>
                  <el-col :lg="24" :xl="12">
                    <el-form-item :label="$t('common.field.accNum')" prop="accountNumber">
                      <el-select
                        v-model="internationalForm.account"
                        :no-data-text="$t('common.formValidation.noEligibleAccounts')"
                        data-testid="accountNumber"
                      >
                        <el-option
                          v-for="item in internationalForm.accountNumberOptions"
                          :key="item.value"
                          :value="item.value"
                          :data-testid="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <component
                  :is="bankInfoComponent"
                  :isShow="isShow"
                  :currency="internationalForm.currency"
                  :country="countryCode"
                ></component>
              </div>
            </div>
            <div class="box_right box">
              <strong class="title"> <span>2</span>{{ $t('deposit.default.sendReceipt') }} </strong>
              <div class="box_right_content form_content_box">
                <div class="info">
                  <p>{{ $t('deposit.intSwift.desc2') }}</p>
                  <p>{{ $t('deposit.intSwift.bussDayInfo') }}</p>
                </div>
                <div class="form_list">
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.accNum')">
                        <div class="special_input">{{ internationalForm.accountNumber }}</div>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="internationalForm.amount"
                          :currency="internationalForm.currency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.upload')" prop="uploadFile">
                        <vUpload :limit="6" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.notes')">
                        <el-input v-model="internationalForm.notes" data-testid="applicationNotes"></el-input>
                        <div class="warn_info p-1 mt-3 text-center text-magenta border border-magenta">
                          <p class="text-white" v-html="$t('deposit.intSwift.tip')"></p>
                        </div>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <div class="button">
                  <el-button
                    type="primary"
                    :loading="loading"
                    :disabled="loading"
                    @click="submitForm()"
                    data-testid="submit"
                  >
                    {{ $t('common.button.submit') }}
                  </el-button>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <Result v-if="successFlag">{{ $t('deposit.default.successMsg') }}</Result>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import mixin from '@/mixins/page/deposit';
import { apiQuery_mt_accounts, apiInternational_bank_payment } from '@/resource';

export default {
  name: 'InternationalSwift',
  components: { vUpload, NumericInput, Result },
  mixins: [mixin],
  data() {
    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) callback(new Error(this.$t('common.formValidation.fileReceipt')));
      else callback();
    };
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', {
              maxLimit: this.maxLimit,
              currency: this.internationalForm.currency
            })
          )
        );
      } else {
        callback();
      }
    };
    return {
      internationalForm: {
        account: '',
        accountNumberOptions: [],
        amount: '',
        accountNumber: '',
        currency: '',
        notes: ''
      },
      internationalRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      minLimit: this.$platform.depositOneAmountMin,
      fileList: [],
      isShow: false,
      formFlag: true,
      successFlag: false
    };
  },
  watch: {
    'internationalForm.account'(value) {
      const values = value.split(' - ');
      this.internationalForm.accountNumber = values[0];
      this.internationalForm.currency = values[1];
      this.isShow = true;
    },
    'internationalForm.accountNumberOptions'(options) {
      // 預設第一筆
      if (options.length > 0) this.internationalForm.account = options[0].value;
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['internationalForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['internationalForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));

                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('resetPassword.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    fetchTradingAccount() {
      apiQuery_mt_accounts({ supportedCurrencies: 'all' }).then(resp => {
        if (resp.data.code == 0) {
          this.internationalForm.accountNumberOptions = resp.data.data
            .filter(f => this.supportedCurrencies(f.currency))
            .map(i => {
              return {
                accountNumber: i.mt4_account,
                value: i.mt4_account + ' - ' + i.currency,
                currency: i.currency
              };
            });
        }
      });
    },
    supportedCurrencies(currency) {
      const defaultCurrencies = ['USD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD'];
      const restrictCountry = [
        6976, //Jordan
        3389, //Oman
        3636, //Brazil
        6777, // Italy
        6907 // United Kingdom
      ];

      switch (true) {
        case restrictCountry.includes(parseInt(this.countryCode)):
          return ['AUD', 'USD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD'].includes(currency);
        case [3382].includes(parseInt(this.countryCode)):
          return ['USD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD', 'BRL'].includes(currency);
        case [1].includes(parseInt(this.countryCode)):
          return ['USD', 'EUR', 'GBP', 'HKD'].includes(currency);
        default:
          return defaultCurrencies.includes(currency);
      }
    },
    submitDeposit() {
      return apiInternational_bank_payment(
        {
          //payment channel is 1, payment type is 2
          mt4Account: this.internationalForm.accountNumber,
          operateAmount: this.internationalForm.amount,
          applicationNotes: this.internationalForm.notes,
          currency: this.internationalForm.currency,
          fileList: this.fileList
        },
        this.token
      );
    }
  },
  mounted() {
    this.fetchTradingAccount();
  },
  computed: {
    HeaderComponent() {
      return () => this.$platform.componentLoader(this.regulator, 'InternationalHeader').component;
    },
    bankInfoComponent() {
      return () => this.$platform.componentLoader(this.regulator, 'InternationalBankInfo').component;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
