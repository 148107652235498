<template>
  <div class="result bg-mirage-gray rounded text-center">
    <div class="result_container">
      <i class="icon el-icon-circle-close text-magenta mb-5" v-if="fail"></i>
      <i class="icon el-icon-warning-outline text-magenta mb-5" v-else-if="info"></i>
      <i class="icon el-icon-circle-check text-light-green mb-5" v-else></i>
      <div class="result_info text-white mb-5">
        <slot></slot>
      </div>
      <router-link :to="{ name: backUrl ? backUrl : 'home' }" class="el-button bg-primary" data-testid="bkToHm">
        {{ $t('common.button.bkToHm') }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'result',
  props: {
    fail: Boolean,
    info: Boolean,
    backUrl: String
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/result.scss';
</style>
